import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import { CssBaseline, NoSsr } from "@material-ui/core"
import Theme from "../theme"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import { graphql } from "gatsby"
import {
  Header,
  Footer,
  CartProvider,
  FAQ,
  SEO,
  MobileOrientation,
} from "../../../components"
import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"
import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"
import "../global_styles/global.css"

addLocaleData([...locale_en, ...locale_es])

export default ({ data, pageContext, location }) => {
  const nodes = data.qaContainer.edges[0].node
  const queryString = require("query-string")
  const parsedQuery = queryString.parse(location.search)
  const faqData = {
    QAs: nodes.faqItems,
    Refs: nodes.refContainer.refItems,
    heroBannerColor: data.qaContainer.edges[0].node.heroBannerColor,
    titleText: data.qaContainer.edges[0].node.pageTitle,
  }
  const langKey = pageContext.locale
  const i18nmessages = require(`../messages/index/${langKey}`)

  const pageMetadata = {
    title: data.qaContainer.edges[0].node.metadataTitle,
    description:
      data.qaContainer.edges[0].node.metadataDescription.internal.content,
    url: location.href,
  }

  const {
    transitionTime,
    defaultTransitionTime,
  } = require("../../../constants/randomConsts")

  return (
    <PageTransition
      transitionTime={
        parsedQuery.transition ? transitionTime : defaultTransitionTime
      }
    >
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <React.Fragment>
          <CssBaseline />
          <ThemeProvider theme={Theme}>
            <MobileOrientation />
            <SEO siteMetadata={pageMetadata} />
            <CartProvider cartId={undefined}>
              <Header
                data={data.header.edges[0].node}
                slug={pageContext.slug}
              />
              <FAQ {...faqData} />
              <Footer
                data={data.footer.edges[0].node}
                bgImgLeft={LeafLeft}
                bgImgRight={LeafRight}
                langKey={langKey}
              />
            </CartProvider>
          </ThemeProvider>
        </React.Fragment>
      </IntlProvider>
    </PageTransition>
  )
}

export const pageQuery = graphql`
  query($locale: String!) {
    qaContainer: allContentfulFaqPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          pageTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          heroBannerColor
          refContainer {
            refItems {
              referenceText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
            }
          }
          faqItems {
            answer {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            question {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          metadataTitle
          metadataDescription {
            internal {
              content
            }
          }
        }
      }
    }
    header: allContentfulHeader {
      edges {
        node {
          shoppingCartLogo {
            file {
              url
            }
          }
          mainLogo {
            file {
              url
            }
          }
        }
      }
    }
    footer: allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          footerMenuItems {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          socialMediaTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          newsletterEmail {
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            emailLable {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            buttonColor
            emailPlaceholder
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
